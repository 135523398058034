@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  } 
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}
.App {
  text-align: center;
}
p{

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p,div{
  font-size:15px;
}
a, a:focus, a:hover{
  color:#EE4F34;
}
.menu-list > .ant-col > .ant-row{
  margin:0px 0px 25px 0px;
}
h1, h2, h3, h4, h5, p, span, div{
  font-family: 'Nunito';
}
.ant-tabs-nav{
  background:#fff;
  z-index:99;
}
.menuItem-title{
  position: relative;
  font-size:20px;
}

.menuItem-title:after{
  border-top: 1px dotted #b5b5b5;
  content: '';
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 30px;
}
.menuItem-title h3{
  display: inline-block;
  position: relative;
  background: #fff;
  z-index: 10;
  padding-right: 10px;
  margin-bottom:5px;
}
.menuItem-dots{
  background-image: linear-gradient(to right, rgba(56, 47, 47, 0.5) 33%, rgba(0, 0, 0, 0) 0%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 20px;
}
.menuItem-price{
  position: absolute;
  right: 0;
  padding-left: 10px;
  background: #fff;
  z-index: 10;
}
h5.menu-category{
  font-family: 'Tangerine', cursive;
  color:#E9B947;
  font-size:40px;
  text-align:center;
  margin-top:20px;
}
.chefSelection-item > .ant-row{
  padding: 8px 15px 0 15px;
}
.chefSelection-item,
.chefSelection-item .menuItem-title h3,
.chefSelection-item .menuItem-price{
  background:#f8f3ea;
}
.ant-layout-sider-children .logo{
  height:32px;
  margin:14px;

}
.ant-layout-sider-children .logo h1{
  color:#fff;

}
.login-form {

}
.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}
.ant-layout-sider-zero-width-trigger{
  top:11px;
  left:0;
}
.logo h1{
  color:#fff;
  padding-left:10px;
}
.ant-layout{
  background:#f0f2f5;
}
.bgColor .ant-layout{
 
}
.bgColor .ant-layout-content .site-layout-background{
  background:#f0f2f5;
  box-shadow: none;
}
.ant-layout-content .site-layout-background{
  background:#fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.ant-card-bordered{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; 
}
.app-logo{
  text-align: center;
    padding: 10px;
    height: 90px;
    background: #ede6f3;
}
.app-logo img{
  width:80px;
}
.page-title{
  background: #fff;
    margin-top: -45px;
    border-radius: 11px;
    padding: 13px 15px;
}
.menuItem-img{
  padding-top:5px;
}
.menu-search{
  margin-right:15px;
}
.container-menu{
  padding:0px;
}
.menuItem-description{
  max-height:45px;
  overflow:hidden;
  margin-bottom:0px;

}
.mt3 .menuItem-name{
  position: relative;
  font-size:20px;
  margin-bottom:0px;
}
.mt3 .menuItem-price{
  position: relative;
  padding-left: 0px;
  font-size:16px;
}
.btnAdd{
  margin-bottom:20px;
}
.ant-form-item textarea{
  font-size:16px;
}
.uploadedImage{
  max-width:200px;
}
.uploadedImage:hover{
  opacity:0.7;
}
.uploadedImage-min{
  max-width:150px;
}
.thumbnailList{
  width:100px;
}
.tableSearchField{
  width: auto;
  float:right;
  margin-bottom:10px;
}
.tableAction{
  margin-right:10px;

}
.tableIcon{
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right:7px;
}
.iconFree{
  background-color: rgb(11, 155, 71);
}
.iconBusy{
  background-color: rgb(204, 22, 22);
}
.formCollapse{
  margin-bottom:40px;
}
.formCollapse .ant-collapse-header{
  font-size:14px;
  font-weight: bold;
}
.inputSmall{
  width:80px;
  display:inline;
  margin-right:20px
}
.formItemInline{
  display: 'inline-block';
  margin-right:10px;
}
.coverImagePublicContainer{
  height:200px;
  position:relative;
  cursor:pointer;
}

.coverImagePublic{
  width:100%;
  object-fit: cover;
  height:200px;
}
@media only screen and (max-width: 700px) {
  .coverImagePublicContainer{
    height:100px;
  }
  .coverImagePublic{
    height:100px;
  }
}
.logoImagePublic{
  position:absolute;
  left:50%;
  bottom:-40px;
  width:200px;
  height:200px;
  object-fit: cover;
  border-radius:100px;
  border:20px solid #fff;
  z-index:11;
}
.logoImagePublicDrawer{
  width:55px;
  height:55px;
  border-radius:27px;
  z-index:11;
}
.menuItemImage{
  width:100%;
  height:120px;
  object-fit: cover;
  border-radius: 10px;
}
.menuItemImage.modal{
  height:200px;
  margin-bottom:20px;
}
.menuItemDetails{
  font-family: 'Nunito';
}
.menuItemDetails .price{
  font-size: 20px;
  font-weight:bold;
  text-align:right;
}
.menuItemDetails p{
  font-family: 'Nunito';
  font-size: 16px;
}
.menuItemDetails .auxiliaryGroup{
  margin-top:17px;
  font-size:14px;
  font-family: 'Nunito';
  font-weight: 700;
  text-transform: uppercase;
  color: #EE4F34;
  margin-bottom:2px;
}
.menuCategorySection{
  margin: 0px 0px 10px 0;
  padding-bottom:30px;
  width:100%;
  border-bottom:5px solid #efefef;
}
.menuCategorySection h2{
  font-family: 'Nunito';
  font-weight: 600;
  color:#EE4F34;
  margin-bottom:25px;
}
h2.small{
  font-size:16px;
  margin-bottom:5px;
  line-height: 22px;
}
.cardButton{
  color:#1c6cb6 !important;
}
.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label{
  padding:0;
}
.ant-form-item{
  margin-bottom:20px;
}
.restaurantHeaderTitle{
  font-family: 'Nunito';
  font-weight: 700;
  font-size:28px;
  background:#ffffff;
  position:relative;
  margin:-30px 0px 10px 0px;
  z-index:12;
  padding:5px 25px 0 15px;
  display: inline-block;
  color:#EE4F34;
  border-top-right-radius: 30px;
}
@media screen and (min-width: 800px) {
  .restaurantHeaderTitle{
    font-size:40px;
  }
}
.ant-drawer-title, .ant-modal-title{
  color:#EE4F34;
  font-family: 'Nunito';
  font-weight: 800;
  font-size: 26px;
  line-height: 30px;
  padding-right:15px;
}
.ant-modal-content, .ant-modal-header{
  border-radius:10px;
}
.restaurantMenuContainer{
  padding:0px 15px;
  margin-bottom:30px !important;
}
.restaurantMenuContainer.headline{
  margin-bottom: 25px !important;
  margin-top: 5px;
}
.restaurantMenuContainer h3{
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 19px;
  margin-bottom:5px;
  line-height: 24px;
}
h3{
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 17px;
  margin-bottom:5px;
  line-height: 24px;
}
.restaurantMenuContainer .price{
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: 800;
}
.restaurantMenuContainer .price span{
  font-weight: normal;
}
.restaurantContainer .ant-row{
  margin-bottom:20px;
}
.ant-row.menuItemContainer{
  border-bottom:1px solid #efefef;
  padding-bottom: 25px;
  margin-bottom: 25px;
  cursor:pointer;
}
.restaurantMenuContainer .ingredients,
.restaurantMenuContainer .description{
  overflow: hidden;
  font-family: 'Nunito';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.restaurantMenuContainer .description{
  margin-bottom:5px;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
-webkit-box-orient: vertical;
}
.restaurantContainer{
  background: #f9f9f6;
}
.contentRow{
  background:#fff;
  padding-bottom:50px;
  margin-bottom:200px;
  border-radius:10px;
}
.restaurantSectionContainer{
  padding:0px 15px;
}
.ant-picker-time-panel-column{
  padding-bottom: 10px !important;
}
.ant-picker-time-panel-column:after{
  height:1px !important;
}
.dropdownRestaurants{
  margin-right: 30px;
}
.dropdownRestaurants a{
  color:#fff;
  font-weight:bold;
}
.spacingTop{
  margin-top:40px;
}
.spacingTopMedium{
  margin-top:20px;
}
h4.widgetTitle{
  text-transform: uppercase;
  font-size:12px;
  color:#6d819e;
  margin-bottom:7px;
}
.widgetContent{
  margin-top:15px;
}
.widgetNumber{
  font-size:40px;
  padding-right:10px;
  line-height:42px;
}
.iconBg{
  background:#e0eafc;
  border-radius: 50%;
  padding:10px;
}
.iconBg svg{
  width: 30px;
  height: 30px;
  color: #3f80ea!important;
}
.ant-breadcrumb a{
  color:#495057;
}
.widgetRestaurantCoverimage{
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
.ant-layout-footer{
  background:#fff;
}
.ant-collapse-borderless{
  background-color: #fff;
}
.rowGutterCard{
  margin-bottom: 16px;
}
.rowGutterCard .ant-col{

}
.status{

}
.status.statusActive{}
.rowDragging{
  background: #fafafa;
  border: 1px solid #ccc;
}
.rowDragging td {
  padding: 16px;
}

.rowDragging .drag-visible {
  visibility: visible;
}
.footer{
  text-align: center;
}
.menuCategoryItem{
  width:'100%';
  height:120px;
  background-color: #efefef;
  overflow:hidden;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}
.menuCategoryImage{
  width:100%;
  height:100%;
  top:0;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
}
.menuCategoryItem h2{
  position: absolute;
  top: 70px;
  left:0px;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(41, 35, 51, 0.5), rgba(41, 35, 51, 0.85));
  padding:5px 15px 5px 15px;
  color:#fff;
  font-size: 17px;
  line-height:21px;
  font-family: 'Nunito';
  font-weight: 700;
  width:100%;
  height:50px;
}
.searchBar{
  width: 100%;
  padding: 0 15px;
}
.searchBar .inner{
  background:#f3f3f3;
  padding:8px 15px;
  border-radius:10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display:flex;
  flex-direction: row;
  align-items: center;
}
.searchBar .searchIcon{
}
.searchBar .searchInputContainer{
  display: flex;
  align-items: center;
  width: 85%;
}
.searchBar input{
  width:85%;
  border: 0px solid;
  font-size: 16px;
  font-family: 'Nunito';
  background:none;
  margin-left:7px;
  color:#282c34;
  line-height:28px;
}
.searchBar input:hover, .searchBar input:focus, .searchBar input:active{
  border: 0px solid;
  outline: none;
}
.searchBar .ant-row{
  margin-bottom:0;
}
.subTitle{
  color: #292333;
  margin:20px 0px 10px 0px;
  font-family: 'Nunito';
  font-weight: 800;
  font-size: 26px;
}
.subTitle.navCategory{
  margin:0;
}
.navCategoryIcon{
  font-size: 24px;
  padding-top: 8px;
  padding-right:10px;
  margin-left: 15px;
  color:#292333;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#999;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:#999;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:#999;
}
.menuCategoryTagContainer{
  margin:20px 0px 0px 0px;
}
.menuCategoryTagContainer a{
  padding:6px 20px;
  background:#f5f5f5;
  margin-right:10px;
  border-radius: 20px;
  font-family: 'Nunito';
  color:#292333;
  font-size: 16px;
  border: 2px solid #292333;
}
.menuCategoryTagContainer a.active{
  background:#292333;
  color:#fff;
  border: 2px solid #292333;
  font-weight: 600;
}
.loader{
  padding: 20px 15px;
  height:500px;
}
.preloaderLogo{
  margin:40px auto;
  width:150px;
  opacity: 0.7;
  text-align: center;
}
.storeIconContainer{
}
.storeIcon{
  padding-right: 10px;
  height:40px;
}
.buttonModalClose,
.buttonModalClose:hover,
.buttonModalClose:focus{
  margin-top:15px;
  padding-bottom:20px;
  background:#EE4F34;
  border-color:#df4b31;
  border-radius:20px;
  font-family: 'Nunito';
  padding:8px 25px;
}
.buttonModalClose.buttomSubmit{
  padding:13px 25px;

}
.buttonModalClose:hover{
  background:#f05b41;
}
.buttonModalClose.buttonAction{
  margin-top:3px;
  margin-bottom:10px;
}
.stickyPoftigo{
  position: fixed;
  bottom:0;
  right:0;
  z-index: 900;
  background:#EF4E34;
  border-top-left-radius: 30px;
  width:75px;
  height: 75px;
  padding:22px 25px 22px 25px;
}
.stickyPoftigoInner{

}
.infoMessage{
  font-family: 'Nunito';
  font-size: 16px;
  margin-left:7px;
}
.infoMessage a{
  font-weight: 800;
}
.menuItemDetails .price{
  margin-bottom:2px;
  line-height: 28px;
}
.sectionTitle{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height:25px;
  margin-top:20px;
}
.sectionTitle.firstItem{
  margin-top:0px;
}
.sectionTitleSeparator{
  height: 7px;
  width: 30px;
  background-color: #FDDA00;
  margin-bottom:3px;
}
.ant-drawer-header-title{
  flex-direction: row-reverse;
}
.langSelector{
  color: #847f8b;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.langSelector img{
  margin-right: 5px;
}
.langSelector.active{
  color:#EF4E34;
  font-weight: 700;
}
.drawerFooter{
  border-top:1px solid #efefef;
  padding-top:20px;
  margin-top:20px;
}
h2.highlight{
  color:#EF4E34;
  font-weight: 700;
}
.drawerFooterRestaurant{
  margin-top:10px;
  padding-top:15px;
}
.drawerFooterRestaurant.bordered{
  border-top: 1px solid #F1F1F1;
  margin-top:40px;
}
.ant-drawer-content{
  border-top:3px solid #292333;
}
.ant-drawer-close{
  color:#292333;
  margin-right: 0px;
}
.cookie-banner{
  background-color: #292333 !important;
  padding:15px
}
.cookie-banner-inner{
  font-family: 'Nunito';
  font-size:17px;
  margin-bottom:5px !important;
}
.cookie-banner-inner p{
  margin-top:5px;
}
.cookie-banner-button{
  background-color:#EF4E34 !important;
  border-radius: 30px !important;
  padding: 8px 25px !important;
  font-weight: 700;
  font-family: 'Nunito';
  line-height: 24px;
  color:#fff !important;
  font-size: 16px;
}
.rating-icon{
  height: 36px;
  width: 36px;
  margin-right:10px;
}
.feedback-container{}
.feedback-container h3{
  font-size:14px;
  font-weight: 600;
  text-transform: uppercase;
  color:#838383;
  margin-top:20px;
}

.feedback-container .comments-box{
  margin-top:20px;
  border-radius:10px;
  padding:10px;
}
.feedback-container .comments-box.waiter-message{
  margin-top:0;
  padding:12px;
}
.request-payment-container{}
.request-payment-container h3{
  font-size:14px;
  font-weight: 600;
  text-transform: uppercase;
  color:#838383;
  margin-top:0px;
}
.request-payment-container .section{
  margin-bottom:15px;
}
.request-payment-container  .ant-radio-wrapper{
  font-size:16px;
  font-weight:600;
  font-family: 'Nunito';
  margin-right:20px;
  padding:10px 0;
}

.ant-btn-lg{
  height: 50px;
}
.buttonModalClose, .buttonModalClose:hover, .buttonModalClose:focus{
  padding:13px 18px;
  font-weight:600;
  border-radius:35px;
}
.buttonModalClose.buttonInline{
  margin-top:0px;
  margin-left:-20px;
}
h2.drawerHeading{
  margin-bottom:3px;
  margin-top:25px;
}
.ant-message-notice-content{
  border-radius:10px;
}
.ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner{
  border-color:#EE4F34;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color:#EE4F34;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(238, 34, 19, 0.12);
}
.ant-radio-inner::after,.ant-radio-inner:focus,
.ant-checkbox-checked .ant-checkbox-inner{
  background-color:#EE4F34 !important;
}
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child{
  border-radius:10px;
  margin-right:10px;
  border-left-width: 1px;
  margin-bottom:10px;
}
.ant-radio-button-wrapper:not(:first-child)::before{
  width:0px;
}
.ant-radio-button-wrapper input.custom-amount{
  margin: 0;
  width: 100px;
  overflow: hidden;
  padding: 0;
  border: 0;
  display: inline;
  background: none;
}

.ant-radio-button-wrapper input.custom-amount.small{
  width: 33px;
}
.ant-radio-button-wrapper input.custom-amount.medium{
  width: 50px;
}
.ant-radio-button-wrapper input.custom-amount:hover,
.ant-radio-button-wrapper input.custom-amount:focus,
.ant-radio-button-wrapper input.custom-amount:focus-visible{
  border:0;
  outline:none;
}
.ant-radio-button-wrapper input.custom-amount::placeholder{
  color:#999;
}
.ant-radio-button-wrapper{
  font-weight: 600;
  font-family: 'Nunito';
  height: 50px;
  line-height: 48px;
  font-size: 16px;
}
.ant-radio-button-wrapper:hover{
  color: #EE4F34;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked{
  color: #EE4F34;
  background: #fff;
  border-color: #EE4F34;
  border-width:2px;
  font-weight: 600;
  font-family: 'Nunito';
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
  color: #EE4F34;
  border-color: #EE4F34;
  border-width:2px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  background: #EE4F34;
  border-width:2px;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 50px;
  font-size: 16px;
  line-height: 48px;
}
.ant-drawer-body{
  padding: 15px 20px;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f8f8f8;
}
.nutritional-name.main{
  font-weight: 600;
}
.nutritional-name.secondary{
  margin-left:20px;
  display:block;
}
.nutritional-table{}
.nutritional-table tr td{
  padding: 8px 16px;
}
.nutritional-value{
  text-align:right;
}
.ant-tabs-tab{
  padding:8px 0px;
  overflow:hidden
}
.ant-tabs-tab .ant-tabs-tab-btn{
  font-size:16px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
 color:#282c34; 
 font-weight: 600;
}
.ant-tabs-tab:hover{
  color:#EE4F34;
}
.ant-tabs-ink-bar{
  background:#FDDA00;

}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height:5px;
}
.nutritional-container{

}
.nutritional-container h5{
  margin-top:10px;
  margin-bottom:2px;
  font-weight: 700;
}
.cta-nutritional{
  margin: 7px 0;
  font-weight:bold;
}
.nutritional-container-ingredients.overflow-active{
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.navCategory-row{
  background:#fff;
}
.vendor-name{
  font-size:18px;
  margin-bottom: 10px;
  color:#EE4F34;
  font-weight: bold;
}
.survey-container{}
.survey-container .survey-question-container{
  margin-bottom:20px;
  padding-bottom:10px;
  border-bottom:1px solid #efefef;
}
.survey-container .survey-question-container .survey-question-hint{
  margin-top:-5px;
  margin-bottom:5px;
  font-size:13px;
}
.survey-container .ant-space{
  margin-right:10px;
  margin-bottom:4px;

}
.survey-container .ant-radio-wrapper{
  padding:10px;
}
.survey-container .ant-checkbox-wrapper{
  padding:10px;
}
.survey-question-container h3{
  font-size:16px;
  color:#292333;
}
.buttonSurvey,
.buttonSurvey:hover,
.buttonSurvey:focus{
  padding: 9px 15px;
  font-size: 16px;
  height: auto;
  margin-top:0px;
  margin-bottom:20px;
}
.display-block{
  display:block;
}
.qrCode-container{
  margin:35px auto 20px auto;
  text-align:center;
}
.text-small{
  font-size:11px;
  text-align:center;
}
.offerImage{
  border:2px solid #FDDA00;
}
.tagOffers{
  position:absolute;
  top:12px;
  right:2px;
  background:#FDDA00;
  line-height: 1;
  padding:3px 8px;
  font-size:12px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-transform: uppercase;
}
.ant-carousel .slick-list{
  padding:0px !important;
}
.ant-carousel .ant-row.restaurantMenuContainer{
  padding:0px 0px 0px 15px;
}
.carousel-full-width .slick-track
{
  width:100% !important;
  padding-right:15px;
}
.carousel-full-width .slick-slide{
  width:100% !important;
}
.offerContainer .menuCategoryItem h2{
  border:2px solid #FDDA00;
  border-bottom-right-radius:10px;
  border-bottom-left-radius:10px;
  border-top:none;
}